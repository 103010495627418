import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TrackByFunction } from '@angular/core';
import {
  AddressModel,
  CityDeliveryMap,
  CityModel,
  CityRemainderProductModel,
  ClientFullModel,
  ComponentType,
  CreateCartRequest,
  Currency,
  datelc,
  DeliveryRangeModel,
  DeliveryType,
  DiscountResultModel,
  Entity,
  GisItem,
  groupBy,
  HX_COMPONENT_NAME,
  HxAuthService,
  HxCallService,
  HxCategoryService,
  HxCityService,
  HxDeliveryService,
  HxDeliveryZoneService,
  HxDgisService,
  HxOrderService,
  HxProductInfoService,
  HxProductService,
  HxRejectionService,
  HxStoreService,
  HxStoreWorkTimeService,
  isMarkerInsidePolygon,
  NotifierEventBusAddress,
  NotifierService,
  PriceShortModel,
  ProductCategoryModel,
  ProductInfoType,
  ProductShortModel,
  SaleCityProductModel,
  SourceSystem,
  StoreFullModel,
  StoreLocationHintModel,
  StoreLocationHintResponse,
  StoreProductRemainderModel,
  toFixed,
  toMap,
  TransferOrderRequest,
  UiError,
  uiLabel,
  YaFeature,
  YaPolygon
} from 'hx-services';
import { catchError, concatMap, debounceTime, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HxErrorHandlerService } from 'hx-component';
import { OrderConfiguration } from '../model/interface';
import { TranslocoService } from '@ngneat/transloco';
import { concat, firstValueFrom, of, Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { addMinutes, differenceInMinutes, format, parseISO, set } from 'date-fns';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hx-order-configurator',
  templateUrl: './order-configurator.component.html',
  styleUrls: ['./order-configurator.component.css']
})
export class HxOrderConfiguratorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() orderConfiguration!: OrderConfiguration;
  @Output() orderCreate = new EventEmitter<{ orderId: number }>();
  @Output() orderTransfer = new EventEmitter<{ orderId: number }>();

  productInfos: ProductBalanceInfo[] = [];
  categories: ProductCategoryModel[] = [];
  isLoading = {
    category: false,
    remainder: false,
    discounts: false,
    main: false,
    decorEnabled: false,
    changeOrder: false,
    products: false,
    stores: false,
    save: false,
    deliveryPrices: false,
    map: false,
  };
  isToday = false;
  selectedCategory?: ProductCategoryModel;
  isShowDropdown = false;
  // stores: StoreBasicModel[] = [];
  deliveryPrices: PriceShortModel[] = [];
  deliveryPrice?: PriceShortModel;
  currency?: Currency;
  lackProducts: ProductShortModel[] = [];
  today = Date.now();
  cityDeliveryArea?: CityDeliveryMap;
  clientAddresses: AddressModel[] = [];
  deliveryAddress?: AddressModel;
  brandId?: number;
  cityId?: number;
  storeId?: number;
  orderDate?: string;
  orderTotal?: number;
  orderSubtotal?: number;
  discounts: DiscountResultModel[] = [];
  stores: StoreItem[] = [];
  deliveryType?: DeliveryType;
  selectedProducts: ProductItem[] = [];
  storePopContentHtml?: SafeHtml;
  productPopContentHtml?: SafeHtml;
  ranges: DeliveryRangeCell[] = [];
  range?: DeliveryRangeCell;
  rangeGroups: RangeGroup[] = [];
  pickupTime?: string;
  pickupTimes: PickupTime[] = [];
  clientId?: number;
  clientPhone?: string;
  closedStoreTitles: string[] = [];
  isCalculateDiscountsFailed = false;
  isEditing = false;
  isManualDeliverySelection = false;
  store?: StoreItem;
  showNearestStore= false;
  dgMap: any;
  DG: any;
  streets: GisItem[] = [];
  streetIsLoading = false;
  streetsInput$ = new Subject<string>();
  gisItem?: GisItem;
  gisFields = 'items.point,items.adm_div,items.type,items.subtype,items.address';
  showAddress = false;
  markerMap: Map<number, any> = new Map<number, any>();
  private prevDate?: string;
  private prevCityId?: number;
  private $destroyed = new Subject<void>();

  constructor(
    private orderService: HxOrderService,
    private productService: HxProductService,
    private storeService: HxStoreService,
    private callService: HxCallService,
    private cityService: HxCityService,
    private zoneService: HxDeliveryZoneService,
    private toastr: ToastrService,
    private errorHandler: HxErrorHandlerService,
    private rejectionService: HxRejectionService,
    private auth: HxAuthService,
    private tr: TranslocoService,
    private notifierService: NotifierService,
    private categoryService: HxCategoryService,
    private productInfoService: HxProductInfoService,
    private domSanitizer: DomSanitizer,
    private deliveryService: HxDeliveryService,
    private dgisService: HxDgisService,
    private storeWorkTimeService: HxStoreWorkTimeService,
    @Optional() @Inject(HX_COMPONENT_NAME) public componentName: ComponentType,
  ) {
  }

  trackById: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['orderConfiguration']) {
      this.clientAddresses = this.orderConfiguration.addresses ?? [];
      this.clientId = this.orderConfiguration.clientId;
      this.clientPhone = this.orderConfiguration.clientPhone;
      this.isEditing = this.orderConfiguration.orderId !== undefined;
      const address = this.orderConfiguration.address;
      if (address) {
        this.deliveryAddress = structuredClone(address);
      }
    }

  }

  ngOnInit(): void {
    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      if (!eb) {
        return;
      }
      if (this.selectedCategory) {
        this.loadRemainders();
      }
    });
    this.orderService.recalcDiscountsObs.pipe(
      takeUntil(this.$destroyed),
      debounceTime(300),
      tap(() => this.isLoading.discounts = true),
      concatMap(() => {
        const orderTime = this.deliveryType === DeliveryType.PICKUP ? this.pickupTime : this.range?.toTime;
        const orderDate = this.orderDate;
        const storeId = this.storeId;
        if (!orderDate || !storeId) {
          return of(undefined);
        }
        const products = this.selectedProducts.map(item => {
          console.log('[order-cfg] item for calc', item);
          const priceId = item.conditions.find(cnd => cnd.storeId === this.storeId)?.prices
            .find(price => price.amount === item.priceAmount)?.id;
          return {
            priceId: priceId ?? 0,
            amount: item.amount,
          };
        }).filter(el => el.priceId !== 0);

        if (this.deliveryType === DeliveryType.DELIVERY && this.deliveryPrice) {
          products.push({
            priceId: this.deliveryPrice.id,
            amount: 1,
          });
        }
        let sourceSystem: SourceSystem | undefined;
        if (this.componentName === ComponentType.manager) {
          sourceSystem = SourceSystem.MANAGER;
        } else if (this.componentName === ComponentType.cc) {
          sourceSystem = SourceSystem.CALLCENTER;
        }
        if (!this.deliveryType) {
          console.warn('[order-configurator] recalcDiscountsObs: deliveryType is undefined');
          return of(undefined);
        }
        return this.orderService.checkDiscount({
          results: this.discounts,
          order: {
            id: this.orderConfiguration.orderId,
            date: orderDate,
            time: orderTime ?? '16:00:00',
            storeId: storeId,
            clientId: this.clientId,
            clientType: this.orderConfiguration.clientType ?? Entity.INDIVIDUAL,
            deliveryType: this.deliveryType,
          },
          sourceSystem: sourceSystem,
          products: products,
        }).pipe(catchError(() => {
          this.isCalculateDiscountsFailed = true;
          return of(undefined);
        }));
      }),
      tap(() => this.isLoading.discounts = false)
    ).subscribe(calcResponse => {
      this.orderTotal = calcResponse?.order.total;
      this.orderSubtotal = calcResponse?.order.subTotal;
      this.discounts = calcResponse?.results ?? [];
    });

    const emptyArr: GisItem[] = [];
    concat(of(emptyArr), this.streetsInput$.pipe(
      takeUntil(this.$destroyed),
      debounceTime(800),
      filter(term => (term ?? '').trim() !== ''),
      tap(() => this.streetIsLoading = true),
      switchMap(term => {
        console.log('[gis] loadStreets', term);
        return this.dgisService.find('/api/vanilla/2gis', term, {fields: this.gisFields, type: 'branch,building', region_id : 68});
      }), tap(() => {
        this.streetIsLoading = false;
      })
    )).subscribe(items=> {
      this.streets = items;
    });
  }

  ngOnDestroy(): void {
    this.unregisterDeliveryHandlers();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  async createNewOrder() {
    this.isLoading.save = true;
    try {
      if (this.orderDate && this.storeId && this.selectedProducts.length > 0) {
        if (!this.rejectionService.getDate() && !this.rejectionService.getStoreId() && !this.rejectionService.getCityId()) {
          this.rejectionService.clearRejection();
          this.rejectionService.initRejection({
            storeId: this.storeId,
            cityId: this.cityId,
            date: this.orderDate,
            orderId: this.orderConfiguration.orderId
          });
        }
        const products = this.selectedProducts.map(sp => ({
          productDefinitionId: sp.id,
          amount: sp.amount,
          priceAmount: sp.priceAmount,
        }));
        if (this.deliveryType === DeliveryType.DELIVERY) {
          if (!this.deliveryPrice) {
            throw new UiError('ord.order-configurator.ts.deliveryPrice.required');
          }
          products.push({
            amount: 1,
            priceAmount: this.deliveryPrice.amount,
            productDefinitionId: this.deliveryPrice.productInfoId,
          });
        }
        const req: CreateCartRequest = {
          callId: this.orderConfiguration.callId,
          date: this.orderDate,
          storeId: this.storeId,
          phone: this.clientPhone,
          products: products,
        };
        if (this.deliveryType === DeliveryType.DELIVERY) {
          if (!this.range) {
            throw new UiError('ord.order-configurator.ts.range.undefined');
          }
          req.rangeId = this.range.id;
          if (this.deliveryAddress) {
            req.address = {
              address: this.deliveryAddress.address,
              latitude: this.deliveryAddress.latitude,
              longitude: this.deliveryAddress.longitude,
              entrance: this.deliveryAddress.entrance,
              floor: this.deliveryAddress.floor,
              flat: this.deliveryAddress.flat,
              addressNote: this.deliveryAddress.note
            };
          }
        }
        try {
          const result = await this.orderService.createOrder(req);
          this.orderCreate.emit({orderId: result.id});
        } catch (err: any) {
          if (err?.error) {
            if (err?.error.message === 'deliveryRange.notFound') {
              this.reloadDeliveryRanges();
            }
            throw err;
          }
        }
      }
    } finally {
      this.isLoading.save = false;
    }
  }

  async changeOrder() {
    if (this.isLoading.changeOrder) {
      this.toastr.info(this.tr.translate('ord.order-configurator.ts.loading'));
      return;
    }
    const orderId = this.orderConfiguration.orderId;
    const orderDate = this.orderDate;
    const storeId = this.storeId;
    const orderTime = this.deliveryType === DeliveryType.PICKUP ? this.pickupTime : this.range?.toTime;
    const deliveryType = this.deliveryType;
    const clientId = this.clientId;
    const clientPhone = this.clientPhone;
    const orderTotal = this.orderTotal;
    const range = this.range;
    if (!orderId) {
      throw new UiError('ord.order-configurator.ts.id.required');
    }
    if (!orderTotal) {
      throw new UiError('ord.order-configurator.ts.orderTotal.required');
    }
    if (!clientId) {
      throw new UiError('ord.order-configurator.ts.clientId.required');
    }
    if (!clientPhone) {
      throw new UiError('ord.order-configurator.ts.clientPhone.required');
    }
    if (!orderDate) {
      throw new UiError('ord.order-configurator.ts.orderDate.required');
    }
    if (!storeId) {
      throw new UiError('ord.order-configurator.ts.storeId.required');
    }
    if (!deliveryType) {
      throw new UiError('ord.order-configurator.ts.deliveryType.required');
    }
    if (deliveryType === DeliveryType.PICKUP && !this.pickupTime) {
      /*this.toastr.error(this.tr.translate('deliver.time.not.pickuped'));*/
      throw new UiError('ord.order-configurator.ts.pickupTime.required');
    }
    if (deliveryType === DeliveryType.DELIVERY) {
      if (!range) {
        throw new UiError('ord.order-configurator.ts.deliveryRange.required');
      }
    }
    if (!orderTime) {
      throw new UiError('ord.order-configurator.ts.orderTime.required');
    }
    if (!this.rejectionService.getDate() && !this.rejectionService.getStoreId() && !this.rejectionService.getCityId()) {
      this.rejectionService.clearRejection();
      this.rejectionService.initRejection({
        storeId: storeId,
        cityId: this.cityId,
        date: orderDate,
      });
    }

    const transferOrderReq: TransferOrderRequest = {
      storeId: storeId,
      orderDate: orderDate,
      orderTime: orderTime,
      deliveryType: deliveryType,
      clientId: clientId,
      clientPhone: clientPhone,
      total: orderTotal,
      discounts: this.discounts,
    };

    if (deliveryType === DeliveryType.DELIVERY) {
      transferOrderReq.address = this.deliveryAddress?.address;
      transferOrderReq.deliveryLatitude = this.deliveryAddress?.latitude;
      transferOrderReq.deliveryLongitude = this.deliveryAddress?.longitude;
      transferOrderReq.rangeId = range?.id;
      transferOrderReq.entrance = this.deliveryAddress?.entrance;
      transferOrderReq.floor = this.deliveryAddress?.floor;
      transferOrderReq.flat = this.deliveryAddress?.flat;
    }

    console.log('[order-configurator] transfer order', transferOrderReq);
    this.isLoading.changeOrder = true;
    try {
      const response = await this.orderService.transferOrder(orderId, transferOrderReq);
      console.info('[order-configurator] transferred order with response', response);
      this.toastr.success(this.tr.translate('ord.order-configurator.ts.orderTransferred'));
      this.orderTransfer.emit({orderId: response.orderId});
    } catch (err: any) {
      console.error('err.error', err.error);
      if (err.error.message === 'productBalance.zero') {
        this.lackProducts = err.error.data.products;
      }
      this.errorHandler.check(err.error);
    } finally {
      this.isLoading.changeOrder = false;
    }
  }

  onDateChanged(date?: string) {
    if (this.orderDate) {
      const date = new Date(this.orderDate);
      const today = new Date();

      this.isToday = date.toDateString() === today.toDateString();
      if (this.cityId && this.brandId) {
        this.loadCoordinates(this.cityId, this.brandId, this.orderDate);
      }
    }
    this.updateProductPrices();

    if (this.cityId) {
      this.loadStoresByCity();
    }
    this.changeListener({date: {previousValue: this.prevDate, currentValue: this.orderDate}});
  }

  onCityChanged(city?: CityModel) {
    if (city && this.brandId) {
      this.cityId = city.id;
      if (this.orderConfiguration.callId && this.cityId) {
        this.callService.saveCityIdForCall(this.orderConfiguration.callId, this.cityId);
      }
      if (this.orderDate) {
        this.loadCoordinates(this.cityId, this.brandId, this.orderDate);
      }
      this.loadCurrency(city.id, this.brandId);
      this.loadStoresByCity();
      this.changeListener({cityId: {previousValue: this.prevCityId, currentValue: this.cityId}});
    }
  }

  onStoreSelected(store: StoreItem) {
    if ((store.missingProducts?.length ?? 0) > 0 || store.isStoreClosed) {
      return;
    }
    this.rejectionService.clearRejection();
    if (this.storeId === store.id) {
      this.storeId = undefined;
      this.store = {} as StoreItem;
    } else {
      store.city = store.city || {id: this.cityId} as CityModel;
      this.cityId = store.cityId;
      this.storeId = store.id;
      this.currency = store.currency;
      this.store = store;
      this.recalculateTotal();
    }
    this.updateProductPrices();
    this.loadRemainders();
    if (this.orderConfiguration.orderId) {
      this.initPickupTimes(store);
    }
  }

  onCategorySelected(category: ProductCategoryModel) {
    if (!this.cityId || !this.orderDate) {
      throw new UiError('ord.order-configurator.ts.unexpected');
    }
    this.selectedCategory = category;
    this.productInfos = [];
    this.getProducts({orderDate: this.orderDate, categoryIds: [this.selectedCategory.id], cityId: this.cityId}).then(productInfos => {
      this.productInfos = productInfos;
      this.loadRemainders();
    });
  }

  onClientUpdated(client?: ClientFullModel) {
    console.log('order conf client = ', client);
    this.clientId = client?.client.id;
    this.clientPhone = client?.client.phone;
    this.clientAddresses = client?.additionalAddresses ?? [];
    this.recalculateTotal();
  }

  async onDeliveryAddressChange() {
    // load delivery range cells
    this.isManualDeliverySelection = false;
    const deliveryAddress = this.deliveryAddress;
    console.debug('[order-configurator] onDeliveryAddressChange', deliveryAddress);
    this.range = undefined;
    this.storeId = undefined;
    this.ranges = [];
    if (deliveryAddress) {
      if (this.componentName === 'manager') {
        await this.loadDeliveryRanges();
        this.isManualDeliverySelection = true;
        return;
      }
      const orderDate = this.orderDate;
      if (orderDate && this.brandId && this.cityId && this.selectedProducts.length > 0) {
        if (deliveryAddress.latitude && deliveryAddress.longitude) {
          const ranges = await this.deliveryService.getNearestDeliveryRanges({
            date: orderDate,
            brandId: this.brandId,
            cityId: this.cityId,
            point: {
              latitude: deliveryAddress.latitude,
              longitude: deliveryAddress.longitude,
            },
            products: this.selectedProducts.map(product => ({
              productDefinitionId: product.id,
              amount: product.amount,
              priceAmount: product.priceAmount,
            })),
          });

          this.ranges = this.initDeliveryRanges(ranges, orderDate);
          if (this.ranges.length > 0) {
            this.registerDeliveryRangesHandler(this.ranges[0].storeId);
          }
        } else {
          await this.loadDeliveryRanges();
          this.isManualDeliverySelection = true;
        }
      }
    }
  }

  onBrandChanged() {
    // console.log('[order-configurator] brand changed', this.brandId);
  }

  onDeliveryTypeChanged() {
    this.store = undefined;
    if (this.deliveryType === DeliveryType.PICKUP) {
      this.loadStoresByCity();
    } else if (this.deliveryType === DeliveryType.DELIVERY) {
      if (this.cityDeliveryArea?.defaultMapType === 'none' || this.componentName === ComponentType.manager) {
        this.loadDeliveryRanges();
      }
    }
  }

  addToCart(event: Event | undefined | null, item: ProductBalanceInfo, priceAmount = 1): void {
    event?.stopPropagation();
    if (this.isEditing) {
      throw new UiError('ord.order-configurator.ts.productModificationForbidden');
    }
    if (item.isProductDisabled) {
      throw new UiError('ord.order-configurator.ts.addToCart.productDisabled');
    }
    if (item.type === ProductInfoType.DELIVERY) {
      throw new UiError('ord.order-configurator.ts.addToCart.deliveryNotAllowed');
    }
    if (!item.forSale) {
      throw new UiError('ord.order-configurator.ts.addToCart.productNotForSale');
    }
    if (this.isProductDisabled(item)) {
      throw new UiError('ord.order-configurator.ts.addToCart.reserveNoFreeProduct');
    }
    if (!priceAmount || !this.selectedCategory || !this.orderDate || !this.cityId || !this.brandId) {
      throw new UiError('ord.order-configurator.ts.addToCart.requiredFields');
    }
    this.orderService.getRemaindersByCityId(this.cityId, {
      date: datelc(this.orderDate),
      categoryIds: [this.selectedCategory.id],
      brandId: this.brandId,
    }).then(remainders => {
      this.streamHandler(undefined, {body: {remainders: remainders}});
      const remainder = remainders.find(rm => rm.productInfo.id === item.id);
      if (remainder) {
        item.remainders = remainder.remainderProducts;
        let sp: ProductItem | undefined = this.selectedProducts.find(p => p.id === item.id);
        if (!sp) {
          sp = {...item, ...{priceAmount: priceAmount, amount: 0}};
          this.selectedProducts.push(sp);
        } else {
          sp.remainders = remainder.remainderProducts;
        }
        const sortedRemainders = remainder.remainderProducts.sort((a, b) => b.remainder - a.remainder);
        if (sortedRemainders.length > 0) {
          const max = sortedRemainders[0].remainder;
          if (toFixed(sp.amount + priceAmount) <= max) {
            sp.amount = toFixed(sp.amount + priceAmount);
          }
        }
        this.updateStores();
      }
      this.checkProducts();
      this.recalculateTotal();
      this.reloadDeliveryRanges();
    });
  }

  onStorePopoverShown(store: StoreItem) {
    let htmlTemplate = this.selectedProducts.map(p => ({
      title: uiLabel(this.tr.getActiveLang(), p.title) ?? '',
      remainder: p.remainders?.find(rm => rm.storeId === store.id)?.remainder ?? 0
    }))
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(a => a.title + ' - ' + a.remainder)
      .join('<br>');

    if (store.mainStore) {
      htmlTemplate = htmlTemplate + `<br><hr>${this.tr.translate('ord.order-configurator.mainStore')}`;
    }

    if (store.locationHint) {
      htmlTemplate = htmlTemplate + `<br><hr><b>${this.tr.translate('ord.order-configurator.locationHint')}: ${store.locationHint}</b>`;
    }

    this.storePopContentHtml = this.domSanitizer.bypassSecurityTrustHtml(htmlTemplate);
  }

  onPickupTimeSelected(timeConst: PickupTime) {
    if (timeConst.isDisabled) {
      return;
    }
    if (this.pickupTime === timeConst.time) {
      this.pickupTime = undefined;
    } else {
      this.pickupTime = timeConst.time;
    }
  }

  onProductPopoverShown(pi: ProductBalanceInfo) {
    if (pi.remainders && pi.remainders.length > 0) {
      const storeMap = toMap(this.stores, st => st.id);
      let htmlTemplate = pi.remainders
        .filter(rm => rm.remainder > 0)
        .map(rm => ({
          storeId: rm.storeId,
          title: uiLabel(this.tr.getActiveLang(), storeMap.get(rm.storeId)?.title) ?? '',
          remainder: rm.remainder,
          mainStore: storeMap.get(rm.storeId)?.mainStore ?? false,
        }))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(rm => {
          let icon;
          if (rm.mainStore) {
            icon = `<span class="m--margin-right-5 m--margin-left-5"><i class="las la-store"></i></span>`;
          } else {
            icon = `<span style="margin-left: 32px;"></span>`;
          }
          return `${icon} <span class="m-badge m-badge--dot m-badge--accent"></span> ${rm.title} - <span class="m--font-boldest">${rm.remainder}</span>`;
        })
        .join('<br>');

      if (pi.description) {
        const descriptionUiLablel = uiLabel(this.tr.getActiveLang(), pi.description) ?? '';
        htmlTemplate = htmlTemplate + `<br><hr><b>${this.tr.translate('ord.order-configurator.description')}: <br>${descriptionUiLablel}</b>`;
      }

      if (htmlTemplate) {
        this.productPopContentHtml = this.domSanitizer.bypassSecurityTrustHtml(htmlTemplate);
      } else {
        this.productPopContentHtml = `<span>${this.tr.translate('ord.order-configurator.ts.noneProductsLeft')}</span>`;
      }
    } else {
      this.productPopContentHtml = `<span>${this.tr.translate('ord.order-configurator.ts.noneProductsLeft')}</span>`;
    }
  }

  removeSelectedProduct(sp: ProductItem, ix: number) {
    if (this.isEditing) {
      throw new UiError('ord.order-configurator.ts.productModificationForbidden');
    }
    this.selectedProducts.splice(ix, 1);
    if (this.selectedProducts.length === 0) {
      this.deliveryType = undefined;
      this.range = undefined;
      this.rangeGroups = [];
      this.storeId = undefined;
      this.ranges = [];
      this.pickupTimes = [];
      this.pickupTime = undefined;
      this.deliveryAddress = undefined;
    }
    this.loadRemainders();
    this.reloadDeliveryRanges();
  }

  trackByCell: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  async toggleRange(range: DeliveryRangeModel) {
    if (this.range?.id === range.id) {
      this.range = undefined;
      this.storeId = undefined;
    } else {
      this.range = range;
      this.storeId = range.storeId;
      if (this.orderDate) {
        this.deliveryPrices = await this.getDeliveryPrices({storeId: this.storeId, date: this.orderDate});
        this.deliveryPrice = this.getDeliveryPrice(this.deliveryAddress);
        if (!this.deliveryPrice) {
          this.isManualDeliverySelection = true;
        } else {
          this.recalculateTotal();
        }
      }
    }
  }

  selectDeliveryPrice(dp: PriceShortModel) {
    this.deliveryPrice = dp;
    this.recalculateTotal();
  }

  getNearestStore() {
    this.showNearestStore = !this.showNearestStore;
    if (this.showNearestStore && !this.dgMap) {
      this.initMap();
    }
  }

  trackByFn(street: GisItem) {
    return street.addressName;
  }

  onStreetChanged(item?: GisItem) {
    let icon = this.DG.icon({
      iconUrl: 'assets/images/blue-dot.png',
      iconSize: [40, 40]
    });
    this.DG.marker([item?.point?.lat, item?.point?.lon], {icon: icon}).addTo(this.dgMap);
    this.DG.popup([item?.point?.lat, item?.point?.lon])
      .setLatLng([item?.point?.lat, item?.point?.lon])
      .setContent(item?.addressName)
      .openOn(this.dgMap);
    this.dgMap.setView([item?.point?.lat, item?.point?.lon]);
  }

  private initMap() {
    this.isLoading.map = true;
    this.dgisService.load().then(() => {
      // @ts-ignore
      this.DG = window['DG'];
      this.DG.then(() => {
        this.dgMap = this.DG.map('map', {
          center: [this.cityDeliveryArea?.center?.lat, this.cityDeliveryArea?.center?.lng],
          zoom: 12
        });

        this.isLoading.map = false;
        this.stores.forEach(st => {
          let icon;
          if ((st.missingProducts?.length ?? 0) > 0 || st.isStoreClosed) {
            icon = this.DG.icon({
              iconUrl: 'assets/images/red-dot.png',
              iconSize: [40, 40]
            });
          } else {
            icon = this.DG.icon({
              iconUrl: 'assets/images/green-dot.png',
              iconSize: [40, 40]
            });
          }
          const marker = this.DG.marker([st.latitude, st.longitude], {icon: icon, label: uiLabel(this.tr.getActiveLang(), st.title) ?? '' })
            .addTo(this.dgMap);
          this.markerMap.set(st.id, marker);
        });
      });
    });
  }

  switchAddressName() {
    if (!this.showAddress) {
      this.stores.forEach(st => {
        this.markerMap.get(st.id).showLabel();
      });
    } else {
      this.stores.forEach(st => {
        this.markerMap.get(st.id).hideLabel();
      });
    }
  }

  private getDeliveryPrice(deliveryAddress?: AddressModel): PriceShortModel | undefined {
    if (!deliveryAddress?.address) {
      return undefined;
    }
    if (this.cityDeliveryArea && ['dgis', 'yandex', 'google'].includes(this.cityDeliveryArea.defaultMapType)) {
      let deliveryPriceId: number | undefined;
      const lat = deliveryAddress.latitude;
      const lng = deliveryAddress.longitude;
      if (lat && lng) {
        const polygon: YaFeature | undefined = this.cityDeliveryArea.deliveryMap.features
          .find(f => f.geometry.type === 'Polygon' && isMarkerInsidePolygon(f.geometry as YaPolygon, lat, lng));
        if (polygon) {
          if (polygon.properties['store-id'] === this.storeId) {
            deliveryPriceId = polygon.properties['price-id'];
          } else {
            deliveryPriceId = polygon.properties['other-stores']?.find(os => os['store-id'] === this.storeId)?.['price-id'];
          }
        }
      }
      console.log('[order-cart] deliveryPriceId', deliveryPriceId);
      if (deliveryPriceId) {
        return this.deliveryPrices.find(p => p.id === deliveryPriceId);
      }
    }
    return undefined;
  }

  private async getDeliveryPrices(params: { storeId: number, date: string }): Promise<PriceShortModel[]> {
    this.isLoading.deliveryPrices = true;
    try {
      const deliveryPrices = await this.productService.getDeliveryPrices(params);
      return deliveryPrices.sort((a, b) => a.value - b.value);
    } finally {
      this.isLoading.deliveryPrices = false;
    }
  }

  private initDeliveryRanges(ranges: DeliveryRangeCell[], orderDate: string): DeliveryRangeCell[] {
    const storeRangesMap = new Map<number, DeliveryRangeCell[]>();
    const rangeCells = ranges.filter(range => range.date === orderDate).map(range => {
      let isDisabled = false;
      range.tooltip = undefined;
      if (range.limit - (range.reserved ?? 0) <= 0) {
        isDisabled = true;
        range.tooltip = this.tr.translate('range.capacity.limit');
      }
      // /*if (this.receiveAfterTime && this.receiveAfterTime.time) {
      //   if (!this.isTimeAllowed(this.receiveAfterTime.time, range.toTime, 30)) {
      //     isDisabled = true;
      //     range.tooltip = this.tr.translate('hx.product-list.receiveAfterTime', {
      //       title: this.receiveAfterTime.productTitle,
      //       receiveAfterTime: this.receiveAfterTime.time
      //     });
      //   }
      // }*/
      const isToday = orderDate === format(new Date(), 'yyyy-MM-dd');
      const nowTime = new Date().getHours() + ':' + new Date().getMinutes();
      if (isToday && !isDisabled && !this.isTimeAllowed(nowTime, range.toTime, 60)) {
        isDisabled = true;
        range.tooltip = this.tr.translate('range.time.limit');
      }
      range.isDisabled = isDisabled;
      let storeRanges = storeRangesMap.get(range.storeId);
      if (storeRanges === undefined) {
        storeRanges = [range];
        storeRangesMap.set(range.storeId, storeRanges);
      } else {
        storeRanges.push(range);
      }
      return range;
    });
    const rangeGroups: RangeGroup[] = [];
    const storeMap = toMap(this.stores, st => st.id);
    storeRangesMap.forEach((ranges, storeId) => {
      const store = storeMap.get(storeId);
      if (store) {
        rangeGroups.push({
          store: store,
          ranges: ranges,
        });
      }
    });
    this.rangeGroups = rangeGroups;
    return rangeCells;
  }

  private registerDeliveryRangesHandler(storeId: number) {
    const orderDate = this.orderDate;
    if (orderDate) {
      this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed), filter(eb => eb !== undefined)).subscribe(eb => {
        this.unregisterDeliveryHandlers();
        this.notifierService.registerHandler(NotifierEventBusAddress.deliveryTime(orderDate, storeId), this.deliveryRangeStreamHandler);
      });
    }
  }

  private unregisterDeliveryHandlers() {
    this.notifierService.unregisterHandlers(new RegExp(`^(${NotifierEventBusAddress.DELIVERY_TIME_PREFIX}).*`));
  }

  private deliveryRangeStreamHandler = (error: any, message: any) => {
    if (error) {
      console.error('error: ', error);
    } else {
      if (message.body && this.orderDate) {
        this.ranges = this.initDeliveryRanges(message.body as DeliveryRangeCell[], this.orderDate);
      }
    }
  };

  private updateProductPrices(): void {
    if (!this.storeId || !this.orderDate) {
      return;
    }
    const orderId = this.orderConfiguration.orderId;
    this.rejectionService.clearRejection();
    this.rejectionService.initRejection({
      storeId: this.storeId,
      cityId: this.cityId,
      date: this.orderDate,
      orderId: orderId,
    });
    if (orderId) {
      this.orderService.getReceiveAfterTime(orderId, this.storeId).then(receiveAfterTime => {
        this.orderService.notifyReceiveAfterTimeChanged(orderId, receiveAfterTime);
      });
    }
  }

  private async loadStoresByCity() {
    if (!this.cityId || !this.orderDate || !this.brandId) {
      console.warn('[order-configurator] loadStoresByCity: cityId, brandId or date is undefined');
      return;
    }
    const ordDate = this.orderDate;
    this.isLoading.stores = true;
    const isPickup = this.deliveryType === DeliveryType.PICKUP;
    const result = await this.storeService.getStoresByCity(this.cityId, { date: ordDate, brandId: this.brandId, pickup: isPickup })
      .finally(() => this.isLoading.stores = false);
    this.stores = result.list.map(store => ({...store, ...{products: [], tooltip: store.address}}));
    const locationHints: StoreLocationHintResponse = await this.cityService.getStoreLocationHints(this.cityId, this.brandId) ;
    let keyValueMap = locationHints.hints.reduce((mapAccumulator, obj) => {
      mapAccumulator.set(obj.storeId, obj);
      return mapAccumulator;
    }, new Map());

    this.stores.forEach(r => {
      const value: StoreLocationHintModel = keyValueMap.get(r.id);
      if (value) {
        r.mapUrl = value.mapUrl;
        r.locationHint= value.locationHint;
      }
      r.isStoreClosed = r.workDates.some(t => t.type === 'CUSTOM' && t.date === ordDate && t.dayOfWeek === new Date(ordDate).getDay() && t.closed);
    });

    if (this.stores.length === 1) {
      this.onStoreSelected(this.stores[0]);
    }

    this.closedStoreTitles = this.stores.filter(r => r.isStoreClosed).map(t => uiLabel(this.tr.getActiveLang(), t.title) ?? '');
    this.isLoading.stores = false;
    this.updateStores();
  }

  private loadCurrency(cityId: number, brandId: number) {
    this.cityService.getCurrencyByCityId(cityId, {brandId: brandId})
      .then(currency => this.currency = currency);
  }

  private updateStores() {
    const findMissingProductsFn: (storeId: number) => ProductItem[] = storeId => this.selectedProducts
      .filter(p => !(p.remainders ?? []).some(rm => rm.storeId === storeId && rm.remainder >= toFixed(p.priceAmount * p.amount)));
    this.stores = this.stores.map(store => ({...store, ...{missingProducts: findMissingProductsFn(store.id)}}));
  }

  private streamHandler = (error: any, message: any) => {
    if (error) {
      console.error('error: ', error);
    } else {
      if (message.body) {
        const messageBody = message.body as { remainders: CityRemainderProductModel[] };
        const remainderMap = toMap(messageBody.remainders, rm => rm.productInfo.id);
        this.productInfos.filter(pbi => remainderMap.has(pbi.id)).forEach(pbi => {
          const productRemainder = remainderMap.get(pbi.id);
          pbi.balance = 0;
          if (productRemainder) {
            pbi.remainders = productRemainder.remainderProducts;
            this.computeCounters(pbi, productRemainder);
            this.computeProductBalanceInfo(pbi);
          }
        });
        this.selectedProducts.filter(sp => remainderMap.has(sp.id)).forEach(sp => {
          const productRemainder = remainderMap.get(sp.id);
          if (productRemainder) {
            sp.remainders = productRemainder.remainderProducts;
          }
        });
        this.updateStores();
      }
    }
  };

  private computeCounters(pbi: ProductBalanceInfo, cityRemainder: CityRemainderProductModel) {
    // const productRemainder = cityRemainder.remainderProducts?.find(rp => rp.storeId === this.storeId) ?? cityRemainder;
    const productRemainder = cityRemainder;
    const isService = cityRemainder.productInfo.type === ProductInfoType.SERVICE;
    if (this.isToday) {
      if (productRemainder.balance !== undefined && !isService) {
        pbi.balance = productRemainder.balance;
      } else if (productRemainder.limit !== undefined && isService) {
        pbi.balance = productRemainder.limit;
      }
    } else {
      if (productRemainder.limit !== undefined) {
        pbi.balance = productRemainder.limit;
      }
    }
    pbi.free = toFixed(productRemainder.remainder);
    pbi.reservedDelivery = productRemainder.reservedDelivery ?? 0;
    pbi.reservedPickUp = productRemainder.reservedPickUp ?? 0;
    pbi.reserved = productRemainder.reserved ?? 0;
    pbi.forSale = productRemainder.forSale;
    pbi.cart = productRemainder.cart ?? 0;
  }

  private computeProductBalanceInfo(pbi: ProductBalanceInfo): void {
    pbi.reservedLabel = this.reserved(pbi);
    pbi.isProductDisabled = this.isProductDisabled(pbi);
  }

  private getCategories(): Promise<ProductCategoryModel[]> {
    this.isLoading.category = true;
    this.isLoading.main = true;

    return firstValueFrom(this.categoryService.getCategories({brandId: this.brandId, limit: 1000}).pipe(
      map(paged => paged.list),
      finalize(() => this.isLoading.main = false)
    ));
  }

  private getProducts(params: { categoryIds: number[], orderDate: string, cityId: number }): Promise<ProductBalanceInfo[]> {
    if (!this.brandId) {
      throw new UiError('ord.order-configurator.ts.getProducts.brandId.undefined');
    }
    this.isLoading.category = true;
    return this.productInfoService.getProductInfosByCityId({
      brandId: this.brandId,
      cityId: params.cityId,
      categoryIds: params.categoryIds,
      fromDate: params.orderDate,
    }).then(productInfos => {
      const saleProducts = productInfos
        .filter(saleProduct => saleProduct.conditions.flatMap(cnd => cnd.prices).length > 0)
        .map(saleProduct => {
          const pbi: ProductBalanceInfo = saleProduct;
          pbi.forSale = true;
          pbi.free = 0;
          pbi.balance = 0;
          pbi.reservedDelivery = 0;
          pbi.reservedPickUp = 0;
          pbi.cart = 0;

          const prices = pbi.conditions.flatMap(cnd => cnd.prices);
          const productPrices: { min: number; max: number; amount: number; }[] = [];
          groupBy(prices, price => price.amount).forEach((amountPrices, amount) => {
            const sortedPrices = amountPrices.sort((a, b) => a.value - b.value);
            if (sortedPrices.length > 0) {
              const min = sortedPrices[0].value;
              const max = sortedPrices.length === 1 ? min : sortedPrices[sortedPrices.length - 1].value;
              productPrices.push({min: min, max: max, amount: amount});
            }
          });
          pbi.prices = productPrices.sort((a, b) => a.amount - b.amount);
          this.computeProductBalanceInfo(pbi);
          return pbi;
        });
      if (saleProducts.length === 0) {
        this.toastr.warning(this.tr.translate('hx.product-list.productListEmpty'));
      }
      this.isLoading.category = false;
      return saleProducts;
    }, err => {
      this.isLoading.category = false;
      return err;
    });
  }

  private registerHandler(cityId: number, date: string) {
    this.notifierService.registerHandler(this.balanceModifyEventName(cityId, date), this.streamHandler);
  }

  private unregisterHandler(cityId: number, date: string) {
    this.notifierService.unregisterHandler(this.balanceModifyEventName(cityId, date), this.streamHandler);
  }

  private balanceModifyEventName(cityId: number, date: string): string {
    return NotifierEventBusAddress.cityRemainder(cityId, date);
  }

  private reserved(item: ProductBalanceInfo): string {
    item.reservedDelivery = item.reservedDelivery || 0;
    item.reservedPickUp = item.reservedPickUp || 0;
    if (this.isToday && this.componentName === ComponentType.cb) {
      return `${item.reservedDelivery} + ${item.reservedPickUp}`;
    }
    return (item.reserved ?? 0) + '';
  }

  private isProductDisabled(item: ProductBalanceInfo, priceAmount?: number): boolean {
    if (!item.forSale) {
      return true;
    }
    if ([ProductInfoType.PRODUCT, ProductInfoType.PROPERTY, ProductInfoType.SERVICE].includes(item.type)) {
      if (!priceAmount) {
        const sortedPrices = item.conditions.flatMap(cnd => cnd.prices).sort((a, b) => a.amount - b.amount);
        priceAmount = sortedPrices.length > 0 ? sortedPrices[0].amount : undefined;
      }
      if (item.type === ProductInfoType.DELIVERY) {
        return true;
      }
      if (!priceAmount) {
        return true;
      }
      if (this.componentName !== ComponentType.cb && (item.free ?? 0) <= 0) {
        return true;
      }
      return (item.free ?? 0) < priceAmount;
    } else {
      return true;
    }
  }

  private loadRemainders(): Promise<CityRemainderProductModel[]> {
    if (!this.orderDate || !this.selectedCategory || !this.cityId || !this.brandId) {
      console.error('[loadRemainders] orderDate/selectedCategory/cityId/brandId is undefined');
      this.toastr.error('Не выбраны категория и/или дата');
      return Promise.reject();
    }
    return this.orderService.getRemaindersByCityId(this.cityId, {
      date: datelc(this.orderDate),
      categoryIds: [this.selectedCategory.id],
      brandId: this.brandId,
    }).then(remainders => {
      this.streamHandler(undefined, {body: {remainders: remainders}});
      this.checkProducts();
      return remainders;
    });
  }

  private checkProducts() {
    if (this.selectedProducts.length > 0) {
      const hasRemainderFn = (productInfo: ProductBalanceInfo) => {
        if (!productInfo.remainders) {
          return false;
        }
        if (this.storeId) {
          return productInfo.remainders.some(rm => rm.storeId === this.storeId && rm.remainder > 0);
        }
        const storeIdArr = this.selectedProducts.map(sp => sp.remainders?.filter(rm => rm.remainder > 0).map(rm => rm.storeId) ?? []);
        let intersectionSet = new Set<number>(storeIdArr[0]);
        storeIdArr.forEach(storeIds => {
          intersectionSet = new Set<number>(storeIds.filter(storeId => intersectionSet.has(storeId)));
        });
        return productInfo.remainders.some(rm => intersectionSet.has(rm.storeId) && rm.remainder > 0);
      };

      this.productInfos.forEach(productInfo => {
        // console.log('check is product disabled %s', productInfo.title.ru, this.isProductDisabled(productInfo), hasRemainderFn(productInfo));
        productInfo.isProductDisabled = this.isProductDisabled(productInfo) || !hasRemainderFn(productInfo);
      });
    }
  }

  private initPickupTimes(store: StoreItem) {
    if (this.orderDate) {
      this.storeWorkTimeService.getStoreWorkTimeByStoreAndDate({storeId: store.id, date: this.orderDate})
        .then(storeWorkDate => {
          if (storeWorkDate) {
            const [openHour, openMinute, openSeconds] = storeWorkDate.openTime.split(':').map(r => Number(r));
            const [closeHour, closeMinute, closeSeconds] = storeWorkDate.closeTime.split(':').map(r => Number(r));
            let startDate = set(Date.now(), {hours: openHour, minutes: openMinute, seconds: openSeconds});
            const endDate = set(Date.now(), {hours: closeHour, minutes: closeMinute, seconds: closeSeconds});
            const pickupTimes: PickupTime[] = [];
            while (differenceInMinutes(startDate, endDate) <= 0) {
              if (differenceInMinutes(startDate, endDate) === 0) {
                startDate = endDate;
              }
              pickupTimes.push({
                time: format(startDate, 'HH:mm'),
                isDisabled: false,
                orderCount: 0
              });
              startDate = addMinutes(startDate, 30);
            }
            this.pickupTimes = pickupTimes;
          }
        });
    }
  }

  private loadCoordinates(cityId: number, brandId: number, orderDate: string) {
    this.zoneService.getStoresCoordinatesByCityId(cityId, brandId, datelc(orderDate)).then(deliveryArea => {
      if (deliveryArea) {
        deliveryArea.deliveryMap.features
          .filter(feature => feature.geometry.type === 'Polygon')
          .forEach(feature => (feature.geometry as YaPolygon).coordinates[0].forEach(item => {
            const b = item[0];
            item[0] = item[1];
            item[1] = b;
          }));
      }
      this.cityDeliveryArea = deliveryArea;
    });
  }

  private recalculateTotal() {
    if (this.storeId) {
      this.orderService.recalcDiscounts();
    }
    // throw new UiError('ord.order-configurator.ts.storeId.undefined', {operation: 'recalculateTotal'});
  }

  private changeListener(changes: { [propName: string]: { previousValue: any; currentValue: any; } }) {
    let cityIdChange = changes['cityId'];
    let dateChange = changes['date'];
    const handlerPairs: { cityId: number, date: string }[] = [];
    if (dateChange) {
      const date = dateChange.currentValue as string;
      this.prevDate = date;
      const prevDate: string | undefined = dateChange.previousValue as string;
      if (prevDate !== date && prevDate && this.cityId) {
        handlerPairs.push({cityId: this.cityId, date: prevDate});
      }
    } else if (cityIdChange) {
      const cityId = cityIdChange.currentValue as number;
      this.prevCityId = cityId;
      const prevCityId: number | undefined = cityIdChange.previousValue as number;
      if (prevCityId !== cityId) {
        this.storeId = undefined;
        if (this.orderDate) {
          handlerPairs.push({cityId: prevCityId, date: this.orderDate});
        }
      }
    }
    handlerPairs.filter(pair => pair.cityId && pair.date).forEach(pair => this.unregisterHandler(pair.cityId, pair.date));

    if (dateChange || cityIdChange) {
      this.selectedProducts = [];
      this.range = undefined;
      this.rangeGroups = [];
      this.ranges = [];
      this.deliveryAddress = undefined;
      this.deliveryType = undefined;
      this.productInfos = [];
      if (this.cityId && this.orderDate) {
        this.registerHandler(this.cityId, this.orderDate);

        this.getCategories().then(categories => {
          this.categories = categories.filter(c => c.components.includes(this.componentName as ComponentType));
          const selectedCategory = this.selectedCategory;
          if (selectedCategory && this.categories.some(category => category.id === selectedCategory.id)) {
            this.onCategorySelected(selectedCategory);
          } else {
            this.onCategorySelected(categories[0]);
          }
        });
      }
      if (this.isEditing && this.cityId && this.brandId && this.orderDate) {
        this.initProducts({cityId: this.cityId, brandId: this.brandId, orderDate: this.orderDate});
      }
    }
  }

  toggleDiscount(discount: DiscountResultModel) {
    discount.enabled = !discount.enabled;
    this.orderService.recalcDiscounts();
  }

  private initProducts(params: { cityId: number, orderDate: string, brandId: number }) {
    const products = this.orderConfiguration.products ?? [];
    const categoryIdSet = new Set<number>(products.filter(p => p.productInfo.type !== ProductInfoType.DELIVERY).map(p => p.categoryId));

    if (categoryIdSet.size > 0) {
      const categoryIdArr = Array.from(categoryIdSet.values());
      const selectedProducts: ProductItem[] = [];
      Promise.all([
        this.orderService.getRemaindersByCityId(params.cityId, {date: datelc(params.orderDate), categoryIds: categoryIdArr, brandId: params.brandId}),
        this.getProducts({orderDate: params.orderDate, cityId: params.cityId, categoryIds: categoryIdArr}),
      ]).then(([remainders, productInfos]) => {
        const remainderMap = toMap(remainders, rm => rm.productInfo.id);
        productInfos.filter(pbi => remainderMap.has(pbi.id)).forEach(pbi => {
          const productRemainder = remainderMap.get(pbi.id);
          pbi.balance = 0;
          if (productRemainder) {
            pbi.remainders = productRemainder.remainderProducts;
            this.computeCounters(pbi, productRemainder);
            this.computeProductBalanceInfo(pbi);
          }
        });
        products.forEach(product => {
          const productDef: ProductBalanceInfo | undefined = productInfos.find(pbi => pbi.id === product.productInfo.id);
          if (productDef && productDef.type !== ProductInfoType.DELIVERY) {
            const remainder = remainders.find(rm => rm.productInfo.id === productDef.id);
            if (!this.isProductDisabled(productDef, product.price.amount) && productDef.forSale && remainder && remainder.remainderProducts.length > 0) {
              productDef.remainders = remainder.remainderProducts;
              const sortedRemainders = remainder.remainderProducts.sort((a, b) => b.remainder - a.remainder);
              const max = sortedRemainders[0].remainder;
              const newProduct: ProductItem = {...productDef, ...{priceAmount: product.price.amount, amount: 0}};
              if (toFixed(product.amount * product.price.amount) <= max) {
                newProduct.amount = product.amount;
              } else {
                newProduct.amount = max;
              }
              selectedProducts.push(newProduct);
            } else {
              this.toastr.error(this.tr.translate('ord.order-configurator.ts.productRemainderEmpty', {title: uiLabel(this.tr.getActiveLang(), productDef.title)}));
            }
          }
        });
        this.selectedProducts = selectedProducts;
        this.checkProducts();
        this.updateStores();
      });
    }
  }

  private loadDeliveryRanges(): Promise<void> {
    const storeIds = this.stores.filter(store => store.hasDelivery).map(store => store.id);
    const orderDate = this.orderDate;
    if (orderDate) {
      return Promise.all(storeIds.map(storeId => this.deliveryService.getDeliveryRanges({
        date: datelc(orderDate),
        storeId: storeId,
      }))).then(res => {
        const ranges: DeliveryRangeModel[] = res.flatMap(el => el);
        this.initDeliveryRanges(ranges, orderDate);
      });
    }
    return Promise.resolve();
  }

  private reloadDeliveryRanges() {
    if (this.deliveryType === DeliveryType.DELIVERY) {
      this.onDeliveryAddressChange();
    }
  }

  private isTimeAllowed(afterTime: string, time: string, limit = 0): boolean {
    const [afterHour, afterMinute] = afterTime.split(':').map(v => Number(v));
    const [rangeToHour, rangeToMinute] = time.split(':').map(v => Number(v));
    const afterReceiveDate = set(parseISO('2020-01-01'), {hours: afterHour, minutes: afterMinute, seconds: 0});
    const rangeToDate = set(parseISO('2020-01-01'), {hours: rangeToHour, minutes: rangeToMinute, seconds: 0});
    // если rangeToDate больше чем afterReceiveDate то значение будет положительным
    return differenceInMinutes(rangeToDate, afterReceiveDate) >= limit;
  }
}

interface ProductItem extends ProductBalanceInfo {
  priceAmount: number;
  amount: number;
}

interface ProductBalanceInfo extends SaleCityProductModel {
  forSale?: boolean;             // computed
  free?: number;                 // computed
  balance?: number;              // computed
  reservedDelivery?: number;     // computed
  reservedPickUp?: number;       // computed
  reserved?: number;             // computed
  reservedLabel?: string;        // computed
  isProductDisabled?: boolean;   // computed
  cart?: number;
  prices?: { min: number; max: number; amount: number; }[];
  remainders?: StoreProductRemainderModel[];
}

interface StoreItem extends StoreFullModel {
  missingProducts?: ProductBalanceInfo[];
  isStoreClosed?: boolean;
  locationHint?: string;
  mapUrl?: string;
}

interface DeliveryRangeCell extends DeliveryRangeModel {
  isDisabled?: boolean;
  isSelected?: boolean;
  tooltip?: string;
  productTitle?: string;
  isLoading?: boolean;
}

interface RangeGroup {
  store: StoreItem;
  ranges: DeliveryRangeCell[];
}

interface PickupTime {
  time: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  orderCount?: number;
}
