import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private keycloak: KeycloakService,
    private toastr: ToastrService,
    private tr: TranslocoService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('request', req);
    const clonedReq = req.clone({ headers: req.headers.set('Accept-Language', this.tr.getActiveLang()) });
    return next.handle(clonedReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        if (error.error && error.error.code) {
          if (error.error.code === 120) {
            this.toastr.warning('sale.after.operday');
          }
        }

        if (error.status === 401) {
          this.keycloak.logout();
        } else if (error.status === 502 || error.status === 500 || error.status === 504) {
          this.toastr.error(this.tr.translate('http-interceptor.error', {error: error.status}));
          this.toastr.info(this.tr.translate('http-interceptor.loaderPage'));
        }
      }

      return observableThrowError(error);
    }));
  }

}
